import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { StaticImage } from "gatsby-plugin-image"

export default function Uslugi() {
  const seo = {
    title: "Usługi - Przychodnia specjalistyczna Medicus-Dukla",
    description: "Usługi - Przychodnia specjalistyczna Medicus-Dukla z siedzibą: Dukla, ul. Trakt Węgierski 16, tel:. 13 43 30 328.",
    url: "https://medicusdukla.pl/uslugi"
  }

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <section className="o-services" data-g="container">
        <h1 className="a-heading-medium">Usługi</h1>
        <div className="o-services__card" data-g="grid vertical-center">
          <div className="left" data-g="4 offset-2@md 10@md offset-2@lg 5@lg">
            <h2 className="a-heading-medium">Opieka pielęgniarska długoterminowa</h2>
            <div data-g={"show hide@lg"} className={"service-img"}>
              <StaticImage className="a-img" src="../images/services/opieka.png" alt="Opieka pielęgniarska" quality={80} formats={['auto', 'webp', 'avif']}/>
            </div>
            <p>
              Opieka nad pacjentami obłożnie chorymi wymagającymi zabiegów pielęgniarskich. Jest ona pełniona przez pielęgniarki opieki długoterminowej w domu pacjenta po wcześniejszym skierowaniu przez lekarza rodzinnego
            </p>
          </div>
          <div className="right" data-g="4 offset-8@lg 3@lg hide show@lg">
            <div className="blue-circle"></div>
            <StaticImage className="a-img" src="../images/services/opieka.png" alt="Opieka pielęgniarska" quality={80} formats={['auto', 'webp', 'avif']}/>
          </div>
        </div>

        <div className="o-services__card" data-g="grid vertical-center">
          <div className="right" data-g="4 offset-3@lg 3@lg hide show@lg">
            <StaticImage className="a-img" src="../images/services/hospicjum.png" alt="Hospicjum domowe" quality={80} formats={['auto', 'webp', 'avif']}/>
          </div>
          <div className="left" data-g="4 offset-2@md 10@md offset-7@lg 5@lg">
            <h2 className="a-heading-medium">Hospicjum domowe</h2>
            <div data-g={"show hide@lg"} className={"service-img"}>
              <StaticImage className="a-img" src="../images/services/hospicjum.png" alt="Hospicjum domowe" quality={80} formats={['auto', 'webp', 'avif']}/>
            </div>
            <p>
              Opieka pełniona jest przez lekarza i pielęgniarkę nad pacjentami w okresie terminalnym. Opieka świadczona jest w domu pacjenta po uprzedniej kwalifikacji lekarskiej.
            </p>
          </div>
        </div>

        <div className="o-services__card" data-g="grid vertical-center">
          <div className="pink-circle"></div>
          <div className="left" data-g="4 offset-2@md 10@md offset-2@lg 5@lg">
            <h2 className="a-heading-medium">Medycyna pracy</h2>
            <div data-g={"show hide@lg"} className={"service-img"}>
              <StaticImage className="a-img" src="../images/services/medycyna-pracy.png" alt="Medycyna pracy" quality={80} formats={['auto', 'webp', 'avif']}/>
            </div>
            <p>
              Przeprowadzamy badania pracowników zgodnie z art. 229 Kodeksu Pracy – wstępne, okresowe i kontrolne.
            </p>
            <br />
            <p className="text-gray">
              lek. Kazimierz Krajmas - badania bez narażeń na stanowisku pracy. <br />
              lek. Anna Klimek - badania z narażeniem na stanowisku pracy.
            </p>
          </div>
          <div className="right" data-g="4 offset-8@lg 3@lg hide show@lg">
            <StaticImage className="a-img" src="../images/services/medycyna-pracy.png" alt="Medycyna pracy" quality={80} formats={['auto', 'webp', 'avif']}/>
          </div>
        </div>


        <div className="o-services__card" data-g="grid vertical-center">
          <div className="right" data-g="4 offset-3@lg 3@lg hide show@lg">
            <StaticImage className="a-img" src="../images/services/badania-kierowcow.png" alt="Badania kierowców" quality={80} formats={['auto', 'webp', 'avif']}/>
          </div>
          <div className="left" data-g="4 offset-2@md 10@md offset-7@lg 5@lg">
            <h2 className="a-heading-medium">Badania kierowców - wszystkie kategorie</h2>
            <div data-g={"show hide@lg"} className={"service-img"}>
              <StaticImage className="a-img" src="../images/services/badania-kierowcow.png" alt="Badania kierowców" quality={80} formats={['auto', 'webp', 'avif']} />
            </div>
            <p>
              lek. Kazimierz Krajmas – wszystkie kategorie prawa jazdy.
            </p>
          </div>
        </div>

        <div className="o-services__card" data-g="grid vertical-center">
          <div className="left" data-g="4 offset-2@md 10@md offset-2@lg 5@lg">
            <h2 className="a-heading-medium">Transport sanitarny</h2>
            <div data-g={"show hide@lg"} className={"service-img"}>
              <StaticImage className="a-img" src="../images/services/transport-sanitarny.png"
                           alt="Transport sanitarny" quality={80} formats={['auto', 'webp', 'avif']} />
            </div>
            <p>
              Bezpłatny dla pacjentów POZ zadeklarowanych w przychodni Medicus-Dukla po otrzymaniu skierowania od lekarza rodzinnego. Świadczymy także usługi odpłatne na życzenie pacjentów. Usługi świadczone własnym samochodem sanitarnym Fiat Doblo Maxi.
            </p>
          </div>
          <div className="right" data-g="4 offset-8@lg 3@lg hide show@lg">
            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" className="transport-circle">
              <path d="M72 72C32.2286 72 3.47693e-06 39.7714 0 6.29444e-06L72 0L72 72Z" fill="#00B8BC"/>
            </svg>
            <StaticImage className="a-img" src="../images/services/transport-sanitarny.png"
                         alt="Transport sanitarny" quality={80} formats={['auto', 'webp', 'avif']}/>
          </div>
        </div>

        <div className="o-services__card" data-g="grid vertical-center">
          <div className="right" data-g="4 offset-3@lg 3@lg hide show@lg">
            <StaticImage className="a-img" src="../images/services/medicus.jpg" alt="Badania kierowców" quality={80} formats={['auto', 'webp', 'avif']}/>
          </div>
          <div className="left" data-g="4 offset-2@md 10@md offset-7@lg 5@lg">
            <h2 className="a-heading-medium">Wypożyczalnia sprzętu</h2>
            <div data-g={"show hide@lg"} className={"service-img"}>
              <StaticImage className="a-img" src="../images/services/medicus.jpg" alt="Badania kierowców" quality={80} formats={['auto', 'webp', 'avif']} />
            </div>
            <p>
              Medicus-Dukla Wypożyczalnia sprzętu rehabilitacyjnego, pielęgnacyjnego, wspomagającego jest dedykowana osobom niesamodzielnym, potrzebującym wsparcia w codziennym funkcjonowaniu. <br/> <br/>
              Kliknij <a href="/projekty-unijne/wypozyczalnia/">tutaj</a> aby zobaczyć więcej szczegółów
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
